<template>
  <div class="public">
    <div class="left-con">
      <div class="title">页面预览</div>
      <div class="phone">
        <div class="top">
          <img src="@/assets/img/information/top.png" />
        </div>
        <div class="page-btn">
          <img class="arrow" src="@/assets/img/information/arrow.png" />
          <span>详情</span>
          <img class="more" src="@/assets/img/information/more.png" />
        </div>
        <div class="info-con">
          <div class="info-title">{{ form.noticeTitle }}</div>
          <div class="detail-con">
            <div class="detail-con-1">
              <div>作者：{{ form.noticeAuther }}</div>
              <div>{{ form.viewNum }}人浏览</div>
            </div>
            <div>发布时间：{{ form.publishTime }}</div>
          </div>
          <div class="img-box" v-if="form.url">
            <el-image :src="imgUrl + form.url" fit="cover"></el-image>
          </div>
          <div class="rich-text-con" v-html="form.noticeContent"></div>
        </div>
      </div>
    </div>
    <el-form
      :rules="dataRules"
      label-width="100px"
      :model="form"
      ref="form"
      v-loading="dataListLoading"
      class="el-form-info"
    >
      <el-form-item v-if="edit" label="编号" prop>{{
        form.noticeNumber
      }}</el-form-item>
      <el-form-item label="标题" prop="noticeTitle">
        {{ form.noticeTitle }}
      </el-form-item>
      <el-form-item label="内容分类" prop="noticeType">{{
        form.noticeTypeName
      }}</el-form-item>
      <el-form-item label="内容封面" v-if="form.url">
        <el-image
          :src="imgUrl + form.url"
          fit="cover"
          style="width: 200px"
        ></el-image>
      </el-form-item>
      <el-form-item label="内容摘要">
        {{ form.noticeSummary ? form.noticeSummary : "-" }}
      </el-form-item>
      <el-form-item label="跳转链接">
        {{ form.noticeRedirectUrl ? form.noticeRedirectUrl : "-" }}
      </el-form-item>
      <el-form-item label="正文内容" prop="noticeContent">
        <!-- 123 -->
        <div v-html="form.noticeContent"></div>
      </el-form-item>
      <el-form-item label="排序权重">
        {{ form.orderWeight }}
      </el-form-item>
      <el-form-item label="浏览次数">
        {{ form.viewNum }}
      </el-form-item>
      <el-form-item label="作者" prop="noticeAuther">
        {{ form.noticeAuther }}
      </el-form-item>
      <el-form-item label="发布时间">
        {{ form.publishTime }}
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import Qs from "qs";
import moment from "moment";
export default {
  name: "publicAdd",
  data() {
    return {
      edit: false,
      form: {
        noticeTitle: "",
        noticeType: "",
        noticeSummary: "",
        noticeRedirectUrl: "",
        noticeContent: "",
        noticeAuther: "",
        orderWeight: "",
        publishTime: "",
        viewNum: "",
        url: "",
      },
      diseasesList: [],
      dataListLoading: false,
      imgUrl: this.$global.imgUrl,
    };
  },
  async mounted() {
    this.form.publishTime = moment().format("YYYY-MM-DD HH:mm:ss");
    let query = this.$route.query;
    if (query.id) {
      this.edit = true;
      this.dataListLoading = true;
      await this.init(query.id);
    }
  },
  methods: {
    async init(id) {
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/notice/getNotice"),
        method: "post",
        params: { stringParam1: id },
      });
      if (res.status) {
        this.form = res.data;
        this.dataListLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.public {
  display: flex;
  .left-con {
    padding-top: 44px;
    padding-right: 24px;
    border-right: 1px solid #eee;
    height: 100%;
    .title {
      font-size: 16px;
      text-align: center;
      padding-bottom: 24px;
    }
    .phone {
      width: 375px;
      height: 667px;
      border: 1px solid #979797;

      .top {
        width: 100%;
        height: 20px;
        img {
          width: 100%;
          height: 20px;
        }
      }
      .page-btn {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        height: 44px;
        padding: 0 15px;
        span {
          font-size: 18px;
        }
        .arrow {
          width: 10px;
          height: 17px;
        }
        .more {
          width: 21px;
          height: 4px;
        }
      }
      .info-con {
        padding: 15px;
        height: calc(100% - 64px);
        overflow: auto;
        .info-title {
          font-size: 20px;
          font-weight: bold;
        }
        .detail-con {
          color: #999999;
          padding: 10px 0 18px;
          .detail-con-1 {
            display: flex;
            justify-content: space-between;
            padding-bottom: 10px;
          }
        }
        .img-box {
          width: 100%;
          height: 290px;
          padding-bottom: 15px;
          .el-image {
            width: 100%;
            height: 100%;
          }
        }
        .rich-text-con {
          /deep/p {
            img {
              width: 100% !important;
              height: auto !important;
            }
          }
        }
      }
    }
  }
}
</style>
